import Head from 'next/head';
import classNames from 'classnames';
import Header from './Header';
import BottomLeftUnits from './BottomLeftUnits';
import Footer from './Footer';
const PageTemplate = ({
  children,
  isFullWidth = false,
  isFullHeight = false
}: {
  children: React.ReactNode;
  isFullWidth?: boolean;
  isFullHeight?: boolean;
}) => {
  return <>
			<Head data-sentry-element="Head" data-sentry-source-file="PageTemplate.tsx">
				<meta name="viewport" content="initial-scale=1, width=device-width, user-scalable=yes" data-sentry-element="meta" data-sentry-source-file="PageTemplate.tsx" />
			</Head>
			<div className={classNames({
      container: true,
      'fixed-100-percent-height': isFullHeight
    })}>
				<Header data-sentry-element="Header" data-sentry-source-file="PageTemplate.tsx" />
				{isFullWidth && <>{children}</>}
				{!isFullWidth && <>
						<div id="page-column">{children}</div>
					</>}
				<Footer data-sentry-element="Footer" data-sentry-source-file="PageTemplate.tsx" />
			</div>
			<BottomLeftUnits data-sentry-element="BottomLeftUnits" data-sentry-source-file="PageTemplate.tsx" />
		</>;
};
export default PageTemplate;